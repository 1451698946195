/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@font-face {
  font-family: "Avenir Bold";
  src: local("Avenir"),
    url("./assets/fonts/AvenirLTStd-Black.otf") format("truetype");
}
@font-face {
  font-family: "Avenir Regular";
  src: local("Avenir"),
    url("./assets/fonts/AvenirLTStd-Book.otf") format("truetype");
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Avenir Regular";
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: white;
  background: #131313;
}
a {
  color: #40c4d8;
  font-weight: 600;
  text-decoration: none; /* Removes underline */
}

.ft-sen {
  font-family: "Avenir Regular", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.ft-tc {
  font-family: "Avenir Regular", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
}

.ft-poppins {
  font-family: "Space Grotesk", sans-serif;
}
.ft-guv {
  font-family: "Space Grotesk", sans-serif !important;
}
.ft-700 {
  font-weight: 700;
}

.ft-900 {
  font-weight: 900;
}

.ft-12 {
  font-size: 0.75em;
}
.ft-14 {
  font-size: 0.875em;
}
.ft-16 {
  font-size: 1em;
}
.ft-18 {
  font-size: 1.125em;
}

.ft-20 {
  font-size: 1.25em;
}

.ft-22 {
  font-size: 1.375em;
}

.ft-24 {
  font-size: 1.5em;
}
.ft-36 {
  font-size: 2.25em;
}
.ft-64 {
  font-size: 4em;
}
.ft-white {
  color: white;
}

.lh-24 {
  line-height: 1.5em;
}

.lh-28 {
  line-height: 1.75em;
}
.lh-64 {
  line-height: 4em;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.b-black {
  background: #000000;
}
.b-black-1 {
  background: #0a0a0a;
}
.b-black-2 {
  background: #1c1c1c;
}

.pad-tb-50 {
  padding: 3.125em 0 3.125em;
}
.section {
  padding: 3.5em 0 3.5em;
}
.section-heading {
  font-family: "Avenir Bold", sans-serif !important;
  color: #f36b4e;
}

.video-block {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.brand-Logo {
  width: 240px;
  margin: 4px;
}
.footer-brand-Logo {
  width: 100px;
}
.img-greyscale {
  filter: grayscale(1);
}
.menu-bg {
  background: rgb(0 0 0 / 39%);
  border-radius: 30px;
}
.menu-bg.dark {
  background-color: black;
}
.menu-bg .nav-link {
  color: white;
}
.menu-bg .btn.btn-chat {
  background-image: linear-gradient(86.15deg, #916cf8 1.66%, #608ed9 96.67%);
  padding: 10px 45px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 20px;
  display: block;
}
.menu-bg .nav-link.active {
  color: white;
  border-bottom: 2px solid #40c4d8;
}
.menu-bg .navbar-toggler {
  border: none;
}
.menu-bg .navbar-toggler-icon {
  background-image: url(./assets/ic-menu.svg);
}
.btn-chat:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.btn-service {
  letter-spacing: 3px;
  padding: 1em;
  background: black;
  border-radius: 20px;
}

.anim-text {
  border-radius: 20px;
}
.para-top {
  border-top: 1px solid white;
  padding-top: 0.75em;
}

.custom-modal .modal-content {
  background: transparent;
}
.custom-modal .modal-header {
  border: none;
}
.custom-modal .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.custom-modal .modal-content {
  border: none;
}
.image-container {
  /*'width: 250px;
  height: 125px;*/
  text-align: center;
}
.image-container img {
  height: 100%;
}
.panel-head {
  padding: 1.5em 0 0.5em;
  border-bottom: 1px solid #f36b4e;
}
.section-2 .section-heading {
  padding: 0.5em 0;
}
.section-2 .b-black-1-1:hover {
  background: rgb(1, 1, 16);
  background: linear-gradient(
    0deg,
    rgb(55 55 57 / 56%) 18%,
    rgba(7, 7, 190, 0.4908088235294118) 62%
  );
}
.section-3 .section-heading {
  padding-bottom: 1.25em;
}
.section-3 .panel-head {
  padding: 0.75em 0 0.5 0;
  margin-bottom: 0.75em;
}
.section-4 .we-support {
  padding-bottom: 1.25em;
}
.section-4 .by-work {
  padding: 2em 0 1.25em;
}
.section-5 .section-heading {
  padding-bottom: 1.25em;
}
.d-card img {
  min-height: 235px;
  object-fit: cover;
}
.d-card-body p {
  min-height: 80px;
}
.carousel-indicators {
  bottom: -25px;
  display: flex;
  align-items: center;
}
.carousel-control-prev,
.carousel-control-next {
  display: none;
}
.carousel-indicators [data-bs-target] {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.carousel-indicators [data-bs-target].active {
  width: 32px;
  height: 32px;
  background-color: black;
  background-image: url(./assets/Select.svg);
}
.bg-contact {
  background-image: url("./assets/contact.gif");
  background-size: cover;
}
.ct-form {
  height: 700px;
  width: 100%;
}
.contact-form .c-row {
  background: white;
  opacity: 0.7;
  color: #7d7d7d;
  border-radius: 8px;
}
.contact-form .form-control {
  background-color: transparent;
}
.contact-list {
  margin: 0;
  padding: 0;
}
.contact-list > li {
  list-style-type: none;
  padding: 0.5em 0 0.5em;
}

.contact-list > li > a {
  text-decoration: none;
  color: white;
}
.ct-block {
  border-bottom: 1px solid #f36b4e;
}
.social-icon {
  width: 100px;
}
.copyright-content {
  color: #f7f7f9;
}
.vimeo-block {
  padding: 56.25% 0 0 0;
  position: relative;
}
.vimeo-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* .navbar-toggler {
  background: white;
} */
.img-container {
  position: relative;
}
.img-container img {
  transition: transform 0.3s ease-in-out;
}
.img-container:hover img {
  transform: scale(1.1);
  /* Adjust scale as needed */
}
.img-container:hover .play-button {
  opacity: 1;
}

.play-button {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgb(52 58 64 / 57%);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
#home {
  position: relative;
}
.control {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgb(255 255 255 / 68%);
  border: 1px solid black;
  border-radius: 20px;
}
.v-control {
  width: 20px;
  height: 20px;
}
.f-helper-text {
  align-items: end;
  justify-content: end;
}
.ct-body-common {
  margin-top: 120px;
}
.f-text {
  text-align: right;
}
.footer-section a {
  color: white;
}
.terms-content {
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .contact-form {
    margin: 1rem;
  }
  .image-container {
    width: 100%;
  }
  .desktop-only {
    display: none;
  }
  #home {
    margin-top: 5.35em;
  }
  .video-block {
    height: 100%;
  }
  .menu-bg {
    background-color: black;
    border-radius: 0;
  }
  .menu-bg .nav-link {
    font-size: 1.5em;
    font-weight: 200;
    text-align: right;
  }
  .section-3 .tt-block {
    padding-top: 1em;
    margin-bottom: 3em !important;
  }
  .mobile .play-button {
    opacity: 1;
  }
  .f-brand-logo {
    text-align: center;
  }
  .f-helper-text {
    justify-content: center;
  }
  .f-text {
    text-align: left;
  }
}
